import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import './LookerFrame.scss';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { getEmbedClient } from '@src/utility/lookerInstance';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { FRAME_CONTENT_DASHBOARD, FRAME_CONTENT_EXPLORE, FRAME_CONTENT_LOOK } from "../../store/constants";
import {
    fetchContentDetails,
    initLooker,
    loadingData,
    loadingDataComplete,
    loadingIframe,
    loadingIframeComplete,
    loadingIframeError
} from "../../store/actions/lookerFrameActions";

const ROOT_LOOKER_FRAME_ID = 'embedded-analytics';

const LookerFrameV1 = ({contentType}) => {
    const dispatch = useDispatch();
    const requestedContent = useSelector((state: RootStateOrAny) => state.lookerFrame.requestedContent);
    const contentPath = useSelector((state: RootStateOrAny) => state.router.location.pathname);
    const {contentId} = useParams();

    useEffect(() => {
        dispatch(initLooker());

        if (contentId !== requestedContent.id && contentType) {
            dispatch(loadingIframe(contentId, contentType));
            dispatch(fetchContentDetails(contentId, contentType));
            return;
        }

        const builder = contentBuilder(requestedContent);
        if (!builder) return;
        builder
            .appendTo('#' + ROOT_LOOKER_FRAME_ID)
            .on(requestedContent.type + ':run:start', () => dispatch(loadingData())
            )
            .on(requestedContent.type + ':run:complete', () => dispatch(loadingDataComplete())
            )
            .build()
            .connect()
            .then(() => dispatch(loadingIframeComplete(requestedContent)))
            .catch((error) => dispatch(loadingIframeError(error)));
    }, [dispatch, requestedContent, contentId, contentType, contentPath]);

    return (
        <div id={ROOT_LOOKER_FRAME_ID}/>
    );
};

export const LookFrameV1: FunctionComponent = () => (<LookerFrameV1 contentType={FRAME_CONTENT_LOOK}/>);

export const DashboardFrameV1: FunctionComponent = () => (<LookerFrameV1 contentType={FRAME_CONTENT_DASHBOARD}/>);

export const ExploreFrameV1: FunctionComponent = () => (<LookerFrameV1 contentType={FRAME_CONTENT_EXPLORE} />);

const contentBuilder = (requestedContent) => {
    if (requestedContent.type === FRAME_CONTENT_LOOK) {
        return LookerEmbedSDK.createLookWithId(requestedContent.id);
    } else if (requestedContent.type === FRAME_CONTENT_DASHBOARD) {
        return LookerEmbedSDK.createDashboardWithId(requestedContent.id);
    } else if(requestedContent.type === FRAME_CONTENT_EXPLORE){
        return LookerEmbedSDK.createExploreWithId(requestedContent.id);
    }
};

const LookerFrame: FunctionComponent<{ contentType: string }> = ({ contentType }) => {
    const dispatch = useDispatch();
    const requestedContent = useSelector((state: RootStateOrAny) => state.lookerFrame.requestedContent);
    const contentPath = useSelector((state: RootStateOrAny) => state.router.location.pathname);
    const { contentId } = useParams<{ contentId: string }>();
    const embedContainer = useRef<HTMLDivElement>(null);

    const [isVisible, setIsVisible] = useState(true);

    // Ref to store the embed client, ensuring it's not recreated on each render
    const embedClientRef = useRef<typeof LookerEmbedSDK | null>(null);

    const contentBuilder = useCallback((requestedContent) => {
        // Initialize embed client only once using the singleton pattern
        if (!embedClientRef.current) {
            embedClientRef.current = getEmbedClient(); // Reuse the embed client
        }

        switch (requestedContent.type) {
            case FRAME_CONTENT_LOOK:
                return embedClientRef.current.createLookWithId(Number(requestedContent.id));
            case FRAME_CONTENT_DASHBOARD:
                return embedClientRef.current.createDashboardWithId(requestedContent.id);
            case FRAME_CONTENT_EXPLORE:
                return embedClientRef.current.createExploreWithId(requestedContent.id);
            default:
                return null;
        }
    }, []);

    const location = useLocation();

    useEffect(() => {
        // Hide embed content when navigating away from the page
        setIsVisible(false);

        // Show content when the user returns to the page
        if (location.pathname === contentPath) {
            setIsVisible(true);
        }

    }, [location, contentPath]); // Runs when the location changes (route change)

    useEffect(() => {
        if (contentId !== requestedContent.id && contentType) {
            dispatch(loadingIframe(contentId, contentType));
            dispatch(fetchContentDetails(contentId, contentType));
            return;
        }

        const builder = contentBuilder(requestedContent);
        if (!builder || !embedContainer.current) return;

        builder
            .appendTo(embedContainer.current)
            .on(`${requestedContent.type}:run:start`, () => dispatch(loadingData()))
            .on(`${requestedContent.type}:run:complete`, () => dispatch(loadingDataComplete()))
            .build()
            .connect()
            .then(() => dispatch(loadingIframeComplete(requestedContent)))
            .catch((error) => dispatch(loadingIframeError(error)));

        // Cleanup function to clear content if no longer visible
        return () => {
            if (embedContainer.current) {
                embedContainer.current.innerHTML = ""; // Clear previous embed content when not visible
            }
        };
    }, [dispatch, requestedContent, contentId, contentType, contentPath, contentBuilder]);

    // Handle showing and hiding the content
    useEffect(() => {
        if (isVisible && embedContainer.current) {
            embedContainer.current.style.display = 'block';  // Show content when visible
        } else if (embedContainer.current) {
            embedContainer.current.style.display = 'none';  // Hide content when not visible
        }
    }, [isVisible]);

    return (
        <div ref={embedContainer} id="embedded-analytics" />
    );
};

// Wrapping components for each type
export const LookFrame: FunctionComponent = () => <LookerFrame contentType={FRAME_CONTENT_LOOK} />;
export const DashboardFrame: FunctionComponent = () => <LookerFrame contentType={FRAME_CONTENT_DASHBOARD} />;
export const ExploreFrame: FunctionComponent = () => <LookerFrame contentType={FRAME_CONTENT_EXPLORE} />;
