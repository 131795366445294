import React from 'react';
import './EmburseLoading.scss';
import EmptyPage from '../EmptyPage/EmptyPage';
import {EmburseLoadingIcon} from '../../assets/logo/bu/icons';

export interface LoadingProps {
  children?: React.ReactNode;
}

export default (props: LoadingProps) => {
  return (
    <EmptyPage>
      <div className="da-emb-loading-container">
        <div className="da-emb-loading">
          <div className="da-emb-loading-img">
            <img src={EmburseLoadingIcon} alt="React Logo"/>
          </div>
        </div>
        {props.children}
      </div>
    </EmptyPage>
  );
};
