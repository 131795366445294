import React from 'react';
import Arc from './Arc';
import './LoadingSpinner.scss';
import {EmburseAnalyticsIcon} from '../../assets/logo/bu/icons';
import EmptyPage from '../EmptyPage/EmptyPage';
import { RootStateOrAny, useSelector } from "react-redux";
import { IFFlags, FFlags, getFlag } from "../../enums/FFlags";
import EmburseLoading from './EmburseLoading';
export interface LoadingProps{
    children?: React.ReactNode
}

export default (props: LoadingProps) => {
    const flags: IFFlags = useSelector(
        (state: RootStateOrAny) => state.user?.userData.flags
    );
    let Loader;
    if (flags?.[getFlag(FFlags.UnifiedEmburseLogo)]) {
        Loader = <EmburseLoading/>;
    } else {
        Loader = <EmptyPage>
        <div className="da-loading-container">
            <div className="da-loading">
                <div className="da-loading-arc">
                    <Arc/>
                </div>
                <div className="da-loading-icon">
                    <img src={EmburseAnalyticsIcon} alt="React Logo"/>
                </div>
            </div>
            {props.children}
        </div>
    </EmptyPage>;
    }
    return (
        Loader
    );
};

