import {LookerEmbedSDK} from "@looker/embed-sdk";
  
const lookerHostName = 'emburse.cloud.looker.com';
const lookerSSOPath = '/app/sign';

// Initialize the LookerEmbedSDK only once
LookerEmbedSDK.init(lookerHostName, lookerSSOPath);

let embedClientInstance: typeof LookerEmbedSDK | null = null;
  
export function getEmbedClient(): typeof LookerEmbedSDK {
  embedClientInstance = LookerEmbedSDK;

  return embedClientInstance;
}