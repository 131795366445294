import React, { SetStateAction, useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { v4 } from "uuid";
import {
  IDashboard,
  ILook,
} from "@src/interfaces";
import {
  Spacer,
} from '@emburse/embark-core';
import {
  IScheduleAPIContext,
  ScheduleAPIContext,
} from "../ScheduleAPIProvider/ScheduleAPIProvider";
import {
  LoadingSpinner,
  Arc
} from "@src/components";
import {
  IReportScheduleDefinition,
} from "@src/interfaces";
import { AuthContext } from "../AuthProvider/AuthProvider";
import { IUseAuthInterface } from "@src/hooks/useAuth";
import { createUseStyles } from "react-jss";
import ScheduleListItem from "@components/Schedule/ScheduleListItem";
import { IFFlags, FFlags, getFlag } from "../../enums/FFlags";

export interface SchedulesListProps {
  item: ILook | IDashboard;
  dashboard?: IDashboard;
  look?: ILook;
  type: "look" | "dashboard";
  setSchedule: React.Dispatch<SetStateAction<any>>;
  setModalState?: React.Dispatch<SetStateAction<any>> | undefined;
}

export default (props: SchedulesListProps) => {
  const { user } = useContext<IUseAuthInterface>(AuthContext);
  const scheduleAPI = useContext<IScheduleAPIContext>(ScheduleAPIContext);
  const flags: IFFlags = useSelector(
    (state: RootStateOrAny) => state.user?.userData.flags
  );

  const useStyles = createUseStyles({
    loading: {
      display: 'flex',
      width: '45px',
      height: 'auto',
      margin: 'auto',
      position:'relative',
    }
  });
  const styles = useStyles();

  let Loader;
  if(flags?.[getFlag(FFlags.UnifiedEmburseLogo)]) {
    Loader = <div className={`loading-container ${styles.loading}`}>
                <div className="da-loading-arc">
                  <Arc/>
                </div>
              </div>;
  } else {
    Loader = <LoadingSpinner/>;
  }
  return (
  <>
    <div>
      {!props.item && Loader}
      {props.item &&
        scheduleAPI.scheduleList
          ?.filter((s: IReportScheduleDefinition) => {
            if (props.dashboard?.id) {
              return s.dashboard_id == props.dashboard.id;
            }
            if (props.look?.id) {
              return s.look_id == props.look.id;
            }
            return false;
          })
          .map((s: IReportScheduleDefinition) => {
            return (
              <div key={v4()}>
                <ScheduleListItem
                  permission={user?.analytics_role}
                  schedule={s}
                  setSchedule={props.setSchedule}
                  setModalState={props.setModalState}
                  folderId={props.item?.folderId}
                  item={props.item as IDashboard | ILook}
                  type={props.type === 'dashboard' ? "dashboard" : "look"}
                />
                <Spacer size={32} />
              </div>
            );
          })}
      </div>
      <Spacer size={32} />
  </>);
};